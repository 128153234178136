import { datatableInit } from "../functions/datatableInit"
import {select2Init} from "../functions/select2Init"
import Swal from "sweetalert2"

const loader = window.customLoader

const userList = () => {

    $('#search-filters').on('click', () => {
        $('.datatable').DataTable().ajax.reload()
    })

    $('#reset-filters').on('click', function () {
        $('#accountType').val('').change();
        $('#roles').val('').change();
        $('#customer').val('').change();
        $('#activity').val('1').change();
        $('.datatable').DataTable().ajax.reload()
    })

    $(document).on('click', '.activity', function () {
        const id = $(this).data('id'),
            type = $(this).data('action'),
            user = $(this).data('user')

        let text, confirmText, url

        if (type === 0)
        {
            text = `dezaktywować konto użytkownika <b>${user}</b>?`
            confirmText = 'Tak, dezaktywuj'
            url = Routing.generate('user_deactivate', {id: id})
        }
        else
        {
            text = `aktywować konto użytkownika <b>${user}</b>?`
            confirmText = 'Tak, aktywuj'
            url = Routing.generate('user_activate', {id: id})
        }

        Swal.fire({
            icon: "question",
            title: "Czy na pewno chcesz",
            html: text,
            allowOutsideClick: false,
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonColor: '#ef5350',
            cancelButtonText: 'Anuluj',
            confirmButtonColor: '#25b372',
            confirmButtonText: confirmText
        }).then(res => {

            if (res.isConfirmed)
            {
                loader.show()
                $.ajax({
                    type: 'patch',
                    url: url
                }).done(d => {

                    let icon, title, text, reload

                    if (d.error)
                    {
                        icon = 'error'
                        title = 'Błąd!'
                        text = d.error
                        reload = false
                    }
                    else
                    {
                        icon = 'success'
                        title = 'Sukces!'
                        text = (type === 0 ? `Dezaktywowano konto ${user}` : `Aktywowano konto ${user}`)
                        reload = true
                    }

                    loader.hide()

                    Swal.fire({
                        icon: icon,
                        title: title,
                        text: text,
                        allowOutsideClick: !reload
                    }).then(() => {
                        if (reload)
                            $('.datatable').DataTable().ajax.reload()
                    })

                }).fail(() => {
                    loader.hide()
                    Swal.fire({
                        icon: 'error',
                        title: 'Wystąpił błąd serwera',
                        confirmButtonColor: '#25b372',
                        allowOutsideClick: false
                    }).then(null)
                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano zmianę aktywności konta',
                    confirmButtonColor: '#25b372',
                    allowOutsideClick: false
                }).then(null)
            }

        })
    })

    $(() => {

        datatableInit('.datatable', {
            columns: [
                { name: 'u.id' },
                { name: 'u.email' },
                { name: 'u.name' },
                { name: 'u.surname' },
                { name: 'u.customerFlag' },
                { name: 'u.roles' },
                { name: 'c.companyName' },
                { name: 'actions', 'orderable': false},
            ],
            order: [1, 'asc'],
            bServerSide: true,
            saveState: true,
            paginate: true,
            processing: true,
            bDestroy: true,
            autoWidth: false,
            lengthMenu: [10, 25, 50, 100],
            ajax: {
                beforeSend: () => {
                    loader.show()
                },
                data: function (d) {
                    d.type = $('#accountType :selected').data('type')
                    d.roles = $('#roles :selected').data('role')
                    d.customer = $('#customer :selected').data('customer')
                    d.active = $('#activity :selected').data('active')
                },
                url: Routing.generate('users_list_ajax'),
                type: 'POST',
                complete: () => {
                    $('body').tooltip({selector: '[data-toggle="tooltip"]'});
                    loader.hide()
                }
            }
        })

        select2Init('select[data-select=users-select2]')

        select2Init('select[data-select=select2-none-search]', {
            minimumResultsForSearch: Infinity
        })

    })

}

window.initUserList = () => {
    userList()
}
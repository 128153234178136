import {select2Init} from "../functions/select2Init";

const roundForm = () => {

    $(document).on('change', 'select[data-bind=location]', function (){

        const value = $(this).val(), button = $('#round_submit')

        button.html(value === '0' ? 'Zapisz' : 'Przejdź do kroku 3')

    })

    $(() => {

        select2Init('select[data-select=select2]')
        select2Init('select[data-select=select2-no-search]', {
            minimumResultsForSearch: Infinity
        })
    })

}

window.initRoundForm = () => {
    roundForm()
}
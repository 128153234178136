import { datatableInit } from "../functions/datatableInit"
import {select2Init} from "../functions/select2Init"
import Swal from "sweetalert2";
import axios from "axios";

const loader = window.customLoader

const correctiveActionList = () => {

    $('#search-filters').on('click', function () {
        $('.datatable').DataTable().ajax.reload()
    })

    $('#reset-filters').on('click', function () {

        $('#number').val('');
        $('#macroregion').val('').change();
        $('#region').val('').change();
        $('#district').val('').change();
        $('#area').val('').change();
        $('#round').val('').change();
        $('#auditor').val('').change();
        $('#feature').val('').change();
        $('#date-planned-from').val('');
        $('#date-planned-to').val('');
        $('#date-implementation-from').val('');
        $('#date-implementation-to').val('');
        $('#result-from').val('');
        $('#result-to').val('');
        $('#status').val('').change();
        $('#questionnaire').val('').change();

        $('.datatable').DataTable().ajax.reload()
    })


    $('.datatable tbody').on('click', '.open-shop', function () {

        loader.show()

        const id = $(this).data('id')
        const tr = $(this).closest('tr')
        const row = $('.datatable').DataTable().row( tr );

        if ( row.child.isShown() ) {
            row.child.hide();
            tr.removeClass('shown');

            loader.hide()
        }
        else {
            $.ajax({
                type: 'post',
                url: Routing.generate('location_details', {id: id}),
            }).then(result => {

                if (result['number'] == null)
                {
                    result['number'] = 'brak'
                }
                if (result['address'] == null)
                {
                    result['address'] = 'brak'
                }
                if (result['city'] == null)
                {
                    result['city'] = 'brak'
                }
                if (result['zipCode'] == null)
                {
                    result['zipCode'] = 'brak'
                }
                if (result['phoneNumber'] == null)
                {
                    result['phoneNumber'] = 'brak'
                }
                if (result['macroregion'] == null)
                {
                    result['macroregion'] = 'brak'
                }
                if (result['region'] == null)
                {
                    result['region'] = 'brak'
                }
                if (result['district'] == null)
                {
                    result['district'] = 'brak'
                }
                if (result['area'] == null)
                {
                    result['area'] = 'brak'
                }

                row.child(

                    '<table class="location-info" style="padding-left:50px;">' +
                    '<tr>' +
                    '<td class="text-center">Numer lokalizacji</td>' +
                    '<td class="text-center">Adres</td>' +
                    '<td class="text-center">Kod pocztowy</td>' +
                    '<td class="text-center">Numer telefonu</td>' +
                    '<td class="text-center">Makroregion</td>' +
                    '<td class="text-center">Region</td>' +
                    '<td class="text-center">Okręg</td>' +
                    '<td class="text-center">Rejon</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="text-center">' + result['number'] + '</td>' +
                    '<td class="text-center">' + result['address'] + ' ' + result['city'] +'</td>' +
                    '<td class="text-center">' + result['zipCode'] + '</td>' +
                    '<td class="text-center">' + result['phoneNumber'] + '</td>' +
                    '<td class="text-center">' + result['macroregion'] + '</td>' +
                    '<td class="text-center">' + result['region'] + '</td>' +
                    '<td class="text-center">' + result['district'] + '</td>' +
                    '<td class="text-center">' + result['area'] + '</td>' +
                    '</tr>' +
                    '</table>'

                ).show();
                tr.addClass('shown');

                loader.hide()
            })
        }
    } );

    $(() => {

        datatableInit('.datatable', {
            columns: [
                { name: 'an.id'},
                { name: 'l.number'},
                { name: 'q.code'},
                { name: 'a.dateImplementation'},
                { name: 's.name'},
                { name: 'actions', 'orderable': false},
            ],
            order: [0, 'desc'],
            bServerSide: true,
            stateSave: true,
            paginate: true,
            processing: true,
            bDestroy: true,
            autoWidth: false,
            lengthMenu: [25,50,100],
            ajax: {
                beforeSend: function (){
                    loader.show()
                },
                data: function ( d ) {
                    d.number = $('#number').val()
                    d.macroregion = $('#macroregion :selected').data('macroregion');
                    d.region = $('#region :selected').data('region');
                    d.district = $('#district :selected').data('district');
                    d.area = $('#area :selected').data('area');
                    d.round = $('#round :selected').data('round');
                    d.auditor = $('#auditor :selected').data('auditor');
                    d.feature = $('#feature :selected').data('feature');
                    d.questionnaire = $('#questionnaire :selected').data('questionnaire');
                    d.dateImplementationFrom = $('#date-implementation-from').val();
                    d.dateImplementationTo = $('#date-implementation-to').val();
                    d.status = $('#status :selected').data('status');
                },
                url: Routing.generate('corrective_action_list_ajax'),
                type: "POST",
                complete: function (){
                    loader.hide()
                }
            },
        })
    })

    select2Init('select[data-select=select2]')

    select2Init('select[data-select=select2-none-search]', {
        minimumResultsForSearch: Infinity
    })


    $(document).on('click', '.remove-corrective', function () {
        const data = new FormData();
        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz usunąć działanie korygujące?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, usuń',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    "beforeSend": function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('corrective_remove', {id: id}),
                    "complete": function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'Działanie korygujące zostało usunięte',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        }).then( () => {
                            $('.datatable').DataTable().ajax.reload()
                        })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się usunąć',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano usuwanie',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })
}

window.initCorrectiveActionList = () => {
    correctiveActionList()
}
import { datatableInit } from "../functions/datatableInit";
import Swal from 'sweetalert2'

const featureLocationList = () => {

    $(document).on('click', '.remove-features', function (e){
        e.preventDefault()
        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz usunąć cechę lokalizacji?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, usuń',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    type: 'post',
                    url: Routing.generate('features_remove', {id: id}),
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'Cecha lokalizacji została usunięta',
                            showCancelButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            cancelButtonText: 'Zamknij',
                            cancelButtonColor: '#3f51b5',
                        }).then( () => {
                            location.reload()
                        })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się usunąć cechy lokalizacji',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano usuwanie cechy lokalizacji',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(() => {

        datatableInit('.datatable')

    })

}

window.initFeatureLocationList = () => {
    featureLocationList()
}
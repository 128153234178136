import {select2Init} from "../functions/select2Init";

const featuresLocationForm = () => {

    $(() => {

        select2Init('select[data-select=select2]')

    })
}

window.initFeaturesLocationForm = () => {
    featuresLocationForm()
}
import { dropifyInit } from "../functions/dropifyInit"

const customerForm = () => {

    $(() => {

        dropifyInit('.dropify')

    })

}

window.initCustomerForm = () => {
    customerForm()
}
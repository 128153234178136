import Swal from "sweetalert2";
import {datatableInit} from "../functions/datatableInit";

const questionnaireList = () => {

    const loader = window.customLoader

    $(document).on('click','.remove-questionnaire', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz usunąć ankietę?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, usuń',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_survey_remove', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'Ankieta została usunięta',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            $("#table-refresh").load(location.href + " #table-refresh")
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się usunąć ankiety',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano usuwanie ankiety',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(document).on('click','.update-questionnaire', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz zaktualizować audyty z wybraną ankietą na urządzeniach audytorów?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, aktualizuj',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('update_survey_list', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'Audyty zostały zaktualizowane',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            $("#table-refresh").load(location.href + " #table-refresh")
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się zaktualizować ankiety',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano aktualizację audytów',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(document).on('click','.edit-status-questionnaire', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy napewno chcesz zmienić status ankiety?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, zmień',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_survey_edit_status', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'Status ankiety został zmieniony',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            $("#table-refresh").load(location.href + " #table-refresh")
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się zmienić statusu ankiety',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano zmianę statusu ankiety',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })
            }
        })
    })

    $(document).on('click','.edit-display-priority-questionnaire', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy chcesz zmienić priorytet wyświetlania ankiety?',
            text: 'Wprowadź nowy priorytet',
            input: 'number',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, zmień',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                if(result.value){

                    $.ajax({
                        beforeSend: function (){
                            loader.show()
                        },
                        type: 'post',
                        url: Routing.generate('surveys_survey_edit_display_priority', {id: id, val: result.value}),
                        complete: function (){
                            loader.hide()
                        }
                    }).done(d => {
                        if (!d.error)
                        {
                            Swal.fire({
                                icon: 'success',
                                title: 'Priorytet wyświetlania ankiety został zmieniony',
                                showConfirmButton: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                confirmButtonText: 'Zamknij',
                                confirmButtonColor: '#3f51b5',
                            }).then(() => {
                                $("#table-refresh").load(location.href + " #table-refresh")
                            } )
                        }
                        else
                        {
                            Swal.fire({
                                icon: 'error',
                                title: 'Nie udało się zmienić priorytetu wyświetlania ankiety',
                                text: d.error,
                                confirmButtonText: 'Zamknij',
                                confirmButtonColor: '#3f51b5',
                            })
                        }

                    })
                }

            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano zmianę priorytetu wyświetlania ankiety',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })
            }
        })
    })


    $(() => {

        datatableInit('.datatable')

    })
}

window.initQuestionnaireList = () => {
    questionnaireList()
}
import {datatableInit} from "../functions/datatableInit";
import Swal from "sweetalert2";

const loader = window.customLoader

const planReportSendList = () => {

    $(document).on('click','.remove-condition', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz usunąć warunek?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, usuń',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_survey_plan_report_send_remove', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'Warunek został usunięty',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            location.reload()
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się usunąć warunku',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano usuwanie warunku',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })


    $(() => {

        datatableInit('.datatable')

    })
}

window.initPlanReportSendList = () => {
    planReportSendList()
}
import { datatableInit } from "../functions/datatableInit";

const customerList = () => {

    $(() => {

        datatableInit('.datatable')

    })

}

window.initCustomerList = () => {
    customerList()
}
import { select2Init } from "../functions/select2Init";

const userForm = () => {

    $(() => {

        select2Init('select[data-select=select2]', {
            minimumResultsForSearch: Infinity
        })

    })

}

window.initUserForm = () => {
    userForm()
}
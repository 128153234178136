import { datatableInit } from "../functions/datatableInit"
import {select2Init} from "../functions/select2Init"

const loader = window.customLoader

const auditListClient = () => {

    $('#search-filters').on('click', function () {

        $('.datatable').DataTable().ajax.reload()
    })

    $('#reset-filters').on('click', function () {
        $('#customer').val('').change();
        $('#number').val('');
        $('#macroregion').val('').change();
        $('#region').val('').change();
        $('#district').val('').change();
        $('#area').val('').change();
        $('#roundname').val('').change();
        $('#auditor').val('').change();
        $('#feature').val('').change();
        $('#date-planned-from').val('');
        $('#date-planned-to').val('');
        $('#date-implementation-from').val('');
        $('#date-implementation-to').val('');
        $('#result-from').val('');
        $('#result-to').val('');
        $('#status').val('').change('');
            $('.datatable').DataTable().ajax.reload()
    })

    $(() => {
        datatableInit('.datatable', {
            columns: [
                { name: 'l.number'},
                { name: 'l.lastAuditDate'},
                { name: 'rn.name'},
                { name: 'ad.name'},
            ],
            order: [1, 'desc'],
            bServerSide: true,
            stateSave: true,
            paginate: true,
            processing: true,
            bDestroy: true,
            autoWidth: false,
            lengthMenu: [100,250,500,1000],
            ajax: {
                beforeSend: function (){
                    loader.show()
                },
                data: function ( d ) {
                    // d.customer = $('#customer :selected').data('customer')
                    d.number = $('#number').val()
                    d.macroregion = $('#macroregion :selected').data('macroregion');
                    d.region = $('#region :selected').data('region');
                    d.district = $('#district :selected').data('district');
                    d.area = $('#area :selected').data('area');
                    d.roundname = $('#roundname :selected').data('roundname');
                    d.auditor = $('#auditor :selected').data('auditor');
                    d.feature = $('#feature :selected').data('feature');
                    // d.datePlannedFrom = $('#date-planned-from').val();
                    // d.datePlannedTo = $('#date-planned-to').val();
                    d.dateImplementationFrom = $('#date-implementation-from').val();
                    d.dateImplementationTo = $('#date-implementation-to').val();
                    // d.resultFrom = $('#result-from').val();
                    // d.resultTo = $('#result-to').val();
                    // d.status = $('#status :selected').data('status');
                },
                url: Routing.generate('audit_list_ajax_client'),
                type: "POST",
                complete: function (){
                    loader.hide()
                }
            },
        })
    })

    select2Init('select[data-audit-select=audit-select2]')

    select2Init('select[data-select=select2-none-search]', {
        minimumResultsForSearch: Infinity
    })
}

window.initAuditListClient = () => {
    auditListClient()
}
export class Loader
{

    constructor() {

        this.holder = $('#loader-holder')

    }

    show() {

        this.holder.css('display', 'flex')

    }

    hide() {

        this.holder.css('display', 'none')

    }

}

import Swal from "sweetalert2"
import axios from "axios"

const loader = window.customLoader

const fillAuditStepOne = () => {
    $(() => {
      $('[data-toggle="tooltip"]').tooltip()
    })


    $(document).on('click', '.finishAudit', function (e) {

        e.preventDefault()

        const id = $(this).data('id'),
            edit = $(this).data('edit')

        let title, text, buttonText, successText

        if (edit)
        {
            title = 'Czy na pewno chcesz przeliczyć punkty?'
            text = ''
            buttonText = 'Tak, przelicz punkty'
            successText = 'Poprawnie przeliczono punkty.'
        }
        else
        {
            title = 'Czy na pewno chcesz zakończyć audyt?'
            text = 'Zakończonego audytu nie będzie można edytować'
            buttonText = 'Tak, zakończ audyt'
            successText = 'Poprawnie zakończono audyt.'
        }

        Swal.fire({
            icon: 'question',
            title: title,
            text: text,
            allowOutsideClick: false,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Anuluj',
            cancelButtonColor: '#ef5350',
            confirmButtonText: buttonText,
            confirmButtonColor: '#25b372',
        }).then( r => {
            if (r.isConfirmed)
            {
                loader.show()

                axios.patch(Routing.generate('audit_finish', {auditId: id})).then(r => r.data).then(data => {

                    let icon, title, text, redirect

                    if (data.error)
                    {
                        icon = 'error'
                        title = 'Błąd!'
                        text = data.error
                        redirect = false
                    }
                    else
                    {
                        icon = 'success'
                        title = 'Sukces!'
                        text = successText
                        redirect = true
                    }

                    loader.hide()

                    Swal.fire({
                        icon: icon,
                        title: title,
                        text: text,
                        allowOutsideClick: !redirect,
                        confirmButtonColor: '#25b372'
                    }).then( () => {

                        if (redirect)
                        {
                            window.location = Routing.generate('audit_list_auditor', {type: 1})
                        }

                    })

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano!',
                    confirmButtonColor: '#25b372'
                }).then(null)
            }
        })

    })

}

window.initFillAuditStepOne = () => {
    fillAuditStepOne()
}
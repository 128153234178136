const auditDetails = () => {

    $(() => {
      $('.openTooltip').tooltip()
    })

}

window.initAuditDetails = () => {
    auditDetails()
}
export function datatableInit(selector, additionalData = {})
{

    if (!$().DataTable) {
        console.warn('Warning - datatables.min.js is not loaded.');
        return;
    }

    $.extend( $.fn.dataTable.defaults, {
        dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
        language: {
            url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Polish.json"
        },
        saveState: true,
        responsive: true,
        columnDefs: [ {
            targets: 'no-sort',
            orderable: false,
        }]
    });

    $(selector).DataTable(additionalData)

}
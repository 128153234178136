import {datatableInit} from "../functions/datatableInit"
import Swal from "sweetalert2"
import {select2Init} from "../functions/select2Init";

const loader = window.customLoader

const roundLocationsForm = customerId => {

    const input = $('select[data-bind=locations]').eq(0)
    const selected = new Set(input.val())

    function selectInput(id, check) {

        if (check)
        {
            selected.add(id)
        }
        else
        {
            selected.delete(id)
        }

        input.val(Array.from(selected))
    }

    $(document).on('change', '.loc', function (){

        const elem = $(this)
        const id = elem.data('id')
        const check = $(this).is(':checked')

        selectInput(id.toString(), check)

    })

    $(document).on('submit', 'form', function (e){

        if (input.val().length === 0) {
            e.preventDefault()
            Swal.fire({
                icon: "error",
                title: "Błąd!",
                text: "Musisz wybrać conajmniej jedną lokalizację!"
            }).then()
        }
    })

    $(document).on('click', '#select-all-round-three', function () {
        const check = $(this).is(':checked')
        const checkboxes = document.getElementsByName('checkbox-round-three');

        checkboxes.forEach((item) => {
            const id = item.dataset.id
            item.checked = check
            selectInput(id, check)
        })
    })

    $('#search-filters').on('click', () => {
        $('.datatable').DataTable().ajax.reload()
    })

    $('#reset-filters').on('click', () =>  {
        $('#macroregion').val('').change();
        $('#region').val('').change();
        $('#district').val('').change();
        $('#area').val('').change();
        $('.datatable').DataTable().ajax.reload()
    })

    $(() => {

        $('form').attr('novalidate', 'novalidate')

        datatableInit('.datatable', {
            columns: [
                {name: 'checkbox', orderable: false, searchable: false},
                {name: 'l.number'},
                {name: 'l.address'},
                {name: 'l.city'},
                {name: 'l.zipCode'},
                {name: 'l.macroregion'},
                {name: 'l.region'},
                {name: 'l.district'},
                {name: 'l.area'},
                {name: 'l.remarks'},
            ],
            order: [1, 'asc'],
            lengthMenu: [100, 250, 500, 1000],
            bServerSide: true,
            stateSave: true,
            paginate: true,
            processing: true,
            bDestroy: true,
            autoWidth: false,
            ajax: {
                beforeSend: function (){
                    loader.show()
                },
                data: (d) => {
                    d.macroregion = $('#macroregion :selected').data('macroregion');
                    d.region = $('#region :selected').data('region');
                    d.district = $('#district :selected').data('district');
                    d.area = $('#area :selected').data('area');
                },
                url: Routing.generate('round_locations', {'customerId': customerId, selected: input.val()}),
                type: "POST",
                complete: function (){
                    loader.hide()
                }
            },
        })

        select2Init('select[data-select=select2]')

    })

}

window.initRoundLocationsForm = customerId => {
    roundLocationsForm(customerId)
}